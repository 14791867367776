import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
export const CDN_URL = "https://cdn.motforex.com/motforex-academy";

const sections = [
  { trigger: "#home", toggleTarget: "#high1" },
  { trigger: "#academy", toggleTarget: "#high2" },
  { trigger: "#channels", toggleTarget: "#high3" },
  { trigger: "#contact", toggleTarget: "#high4" },
];
sections.forEach(({ trigger, toggleTarget }, index) => {
  if (index === sections.length - 1) {
    ScrollTrigger.create({
      trigger: trigger,
      start: "top+=4000 center",
      end: "bottom+=4100 bottom",
      toggleClass: { targets: toggleTarget, className: "active" },
    });
  } else if (index === 2) {
    ScrollTrigger.create({
      trigger: trigger,
      start: "top center",
      end: "bottom+=4400 bottom",
      toggleClass: { targets: toggleTarget, className: "active" },
    });
  } else {
    ScrollTrigger.create({
      trigger: trigger,
      start: "top center",
      end: "bottom center",
      toggleClass: { targets: toggleTarget, className: "active" },
    });
  }
});

// SECTION 2

const badges = document.querySelectorAll(".badge");
const horLine = document.querySelectorAll(".hor-line");
const section2Item = document.querySelectorAll(".section2-item");
badges[0].classList.add("active");
badges.forEach((chip, index) => {
  chip.addEventListener("click", () => {
    // Add a new class when the element is clicked
    chip.classList.add("active");

    badges.forEach((otherChip) => {
      if (otherChip !== chip) {
        otherChip.classList.remove("active");
      }
    });
    horLine.forEach((item, i) => {
      if (i === index) {
        item.classList.remove("hidden");
        item.classList.add("block");
      } else {
        item.classList.remove("block");
        item.classList.add("hidden");
      }
    });
    section2Item.forEach((item, i) => {
      if (i === index) {
        item.classList.remove("hidden");
        item.classList.add("flex");
      } else {
        item.classList.remove("flex");
        item.classList.add("hidden");
      }
    });
  });
});

// SECTION 3
gsap.to("#slideContainer", {
  x: () =>
    -(
      document.querySelector("#slideContainer").scrollWidth -
      (window.innerWidth - 450)
    ), // Calculate the total width for scrolling
  ease: "none",
  scrollTrigger: {
    trigger: "#pinContainer",
    pin: true, // Pin the container while scrolling
    scrub: true, // Smooth scroll syncing
    start: "top top+=144", // Start when #pinContainer hits the top of the viewport
    end: () =>
      "+=" +
      (document.querySelector("#slideContainer").scrollWidth +
        window.innerWidth), // Extend the end point artificially
  },
});

const scrollers = document.querySelectorAll(".scroller");

// If a user hasn't opted in for recuded motion, then we add the animation
if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
  addAnimation();
}

function addAnimation() {
  scrollers.forEach((scroller) => {
    scroller.setAttribute("data-animated", true);
    const scrollerInner = scroller.querySelector(".scroller__inner");
    const scrollerContent = Array.from(scrollerInner.children);
    scrollerContent.forEach((item) => {
      const duplicatedItem = item.cloneNode(true);
      duplicatedItem.setAttribute("aria-hidden", true);
      scrollerInner.appendChild(duplicatedItem);
    });
  });
}
const acc = document.getElementsByClassName("accordion");

for (let i = 0; i < acc.length; i++) {
  acc[i].addEventListener("click", function () {
    this.classList.toggle("active");

    const panel = this.nextElementSibling;
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
    }
    const plusIcon = this.querySelector(".icon-plus");
    const minusIcon = this.querySelector(".icon-minus");

    if (plusIcon && minusIcon) {
      plusIcon.classList.toggle("hidden");
      minusIcon.classList.toggle("hidden");
    }
  });
}

document
  .getElementById("contactForm")
  .addEventListener("submit", function (event) {
    event.preventDefault();

    // Get form field values
    const nameField = document.getElementById("name");
    const emailField = document.getElementById("email");
    const titleField = document.getElementById("title");
    const descriptionField = document.getElementById("description");

    const name = nameField.value;
    const email = emailField.value;
    const title = titleField.value;
    const description = descriptionField.value;

    // Construct mailto link
    const subject = encodeURIComponent(`${name} - ${email}`);
    const body = encodeURIComponent(`${title} \n ${description}`);
    const mailtoLink = `mailto:support@motforex.com?subject=${subject}&body=${body}`;

    // Redirect to mailto link
    window.location.href = mailtoLink;

    // Clear form fields
    nameField.value = "";
    emailField.value = "";
    titleField.value = "";
    descriptionField.value = "";
  });

document.addEventListener("DOMContentLoaded", () => {
  document
    .getElementById("subscribeForm")
    .addEventListener("submit", function (event) {
      event.preventDefault();

      const emailSubscribeField = document.getElementById("email-subcribe");
      const subscribeBtn = document.getElementById("subscribe-btn");
      const sendText = document.getElementById("send-text");
      const sendIcon = document.getElementById("send-icon");
      const sentIcon = document.getElementById("sent-icon");

      const email = emailSubscribeField.value;

      if (!email) {
        alert("Имэйл хаягаа оруулна уу!");
        return;
      }
      subscribeBtn.disabled = true;
      // Ensure reCAPTCHA is ready before executing
      if (typeof grecaptcha !== "undefined") {
        grecaptcha.ready(() => {
          grecaptcha
            .execute("6Lf-wpAqAAAAAJ763ZK1Yy1wf3YmdMsZB997v8RE", {
              action: "submit",
            })
            .then((captcha) => {
              fetch("https://api.motforex.com/info/academy/subscription", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  email,
                  captcha,
                }),
              })
                .then((response) => {
                  if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                  }
                  return response.json();
                })
                .then((result) => {
                  sendText.innerText = "Илгээсэн";
                  sendIcon.classList.add("hidden");
                  sentIcon.classList.remove("hidden");
                })
                .catch((error) => {
                  console.error("Error:", error);
                });

              setTimeout(() => {
                sendText.innerText = "Илгээх";
                sendIcon.classList.remove("hidden");
                sentIcon.classList.add("hidden");
                subscribeBtn.disabled = false;
                emailSubscribeField.value = "";
              }, 3000);
            })
            .catch((error) => {
              console.error("reCAPTCHA error:", error);
              alert("reCAPTCHA шалгалт амжилтгүй боллоо. Дахин оролдоно уу.");
            });
        });
      } else {
        alert(
          "reCAPTCHA script is not loaded. Please check your internet connection or reCAPTCHA setup."
        );
        subscribeBtn.disabled;
      }
    });
});

const x = document.getElementById("myLinks");
const hamburger = document.getElementById("hamburger");
const close = document.getElementById("close");
const overlay = document.getElementById("menuOverlay");
function myFunction() {
  if (x.style.display === "block") {
    close.classList.add("hidden");
    close.classList.remove("block");
    hamburger.classList.add("block");
    hamburger.classList.remove("hidden");
    x.style.display = "none";
    overlay.style.display = "none";
  } else {
    hamburger.classList.remove("block");
    hamburger.classList.add("hidden");
    close.classList.add("block");
    close.classList.remove("hidden");
    x.style.display = "block";
    overlay.style.display = "block";
  }
}

window.myFunction = myFunction;

function closeMenu() {
  x.style.display = "none";
  overlay.style.display = "none";
  close.classList.add("hidden");
  close.classList.remove("block");
  hamburger.classList.add("block");
  hamburger.classList.remove("hidden");
}
window.closeMenu = closeMenu;

function dropDown() {
  document.getElementById("myDropdown").classList.toggle("show");
}

window.dropDown = dropDown;
window.onclick = function (event) {
  if (!event.target.matches(".dropbtn")) {
    var dropdowns = document.getElementsByClassName("dropdown-content");
    var i;
    for (i = 0; i < dropdowns.length; i++) {
      var openDropdown = dropdowns[i];
      if (openDropdown.classList.contains("show")) {
        openDropdown.classList.remove("show");
      }
    }
  }
};
